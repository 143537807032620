import React, {FC, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Coordinates, ProfileApi} from "../../../../services/Api/types";
import {ReactComponent as MarkerSVG} from "../../../../images/svg/marker.svg";
import {LoadingContainer} from "../../../../components/Common/Loading/Loading";
import {StoreFilter} from "../services/types";


interface Props {
    filter?: StoreFilter,
    onChangeCoordinates: (coordinates: Coordinates) => void;
    onChangeOrderType: (type: string) => void;
    onClose: () => void;
    profile?: ProfileApi | undefined,
    openStore: (profile: ProfileApi) => void,
}

const RequiredLocationModalComponnent: FC<Props> = (props) => {
    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    let coordinates = props.filter?.coordinates;
    // useEffect(() => {
    //     if (props.coordinates) {
    //         setLoading(false);
    //         setShow(false);
    //     } else {
    //         setLoading(false)
    //     }
    // }, [props.coordinates]);

    const geolocation = () => {
        if ("geolocation" in navigator) {
        } else {
            alert("Geolokalizacja niedostępna")
            return;
        }
        setLoading(true);
        setError("");
        navigator.geolocation.getCurrentPosition(function (position) {
            props.onChangeCoordinates({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            } as Coordinates)
            setLoading(false);
            setError("");
        }, (err) => {
            console.log(err);
            if (err && err.message === "User denied Geolocation") {
                setError("Użytkownik zablokował dostęp do geolokalizacji");
            } else {
                setError("Nie udało się pobrać lokalizacji")
            }
            // alert("Geolokalizacja niedostępna: " + err.message)
            // alert("Geolokalizacja niedostępna")
            setLoading(false);
        });
    }

    const onShow = () => {
    }
    const onHide = () => {
        props.onClose()
    }
    const renderChooseType = () => {
        return (
            <>
                <div className={"text-center mb-4"}>
                    <h5 style={{fontWeight: 300}}>
                        Wybierz rodzaj zamówienia
                    </h5>
                </div>

                <button type="button"
                        className={`btn w-100 mb-2 btn-outline-primary${props.filter?.type == "DELIVERY" ? " active" : ""}`}
                        onClick={() => props.onChangeOrderType("DELIVERY")}>
                    Dostawa
                </button>
                <button type="button"
                        className={`btn w-100 btn-outline-primary${props.filter?.type == "PICK_UP" ? " active" : ""}`}
                        onClick={() => props.onChangeOrderType("PICK_UP")}>
                    Na miejscu
                </button>
            </>
        )
    }
    const renderView = () => {
        if (!props.profile) return null
        if (props.filter?.type === undefined) {
            return (
                <>
                    {renderChooseType()}
                </>
            )
        }
        if (props.filter.type === "PICK_UP") {
            return (
                <div className={"text-center mb-4"}>
                    <MarkerSVG style={{height: 50, width: 50}} className={"mb-4 mt-4"}/>
                    <div className={"mt-2"}>
                        <Button className={"btn btn-primary"} onClick={() => {
                            if (props.profile) props.openStore(props.profile)
                        }}>Otwórz sklep</Button>
                    </div>
                </div>

            )
        }
        if (coordinates === undefined || !coordinates) {
            return (
                <>
                    <div className={"text-center mb-4"}>
                        <MarkerSVG style={{height: 50, width: 50}} className={"mb-4 mt-4"}/>
                        <h5 style={{fontWeight: 300}}>
                            Brak lokalizacji lub adresu
                        </h5>
                        <small>
                            Zlokalizuj siebie na mapie lub wpisza adres dostawy aby przejść do sklepu
                        </small>
                    </div>

                    {error && (
                        <p className={"text-danger text-center"}>
                            {error}
                        </p>
                    )}
                    {loading ? (
                        <>
                            <LoadingContainer value={"Trwa pobieranie lokalizacji ... "}/>
                            <Button variant="default" className={"w-100 mb-2"}
                                    onClick={() => setShow(false)}>
                                Anuluj
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button variant="primary" className={"w-100 mb-2"}
                                    onClick={geolocation}>
                                Zlokalizuj
                            </Button>
                            <Button variant="default" className={"w-100 mb-2"}
                                    onClick={() => props.onClose()}>
                                Wpisz adres
                            </Button>
                        </>
                    )}
                </>
            )
        }
        if (!props.profile?.in_delivery_zone) {
            return (
                <>

                    <div className={"text-center mb-4"}>
                        <MarkerSVG style={{height: 50, width: 50}} className={"mb-4 mt-4"}/>
                        <h5 style={{fontWeight: 300}}>
                            Jesteś poza strefą dostaw tego lokalu
                        </h5>
                        <small>
                            Zmien lokal albo wybierz inny adres dostawy
                        </small>
                    </div>
                </>
            )
        }

        return (
            <div className={"text-center mb-4"}>
                <MarkerSVG style={{height: 50, width: 50}} className={"mb-4 mt-4"}/>
                <div className={"mt-2"}>
                    <Button className={"btn btn-primary"} onClick={() => {
                        if (props.profile) props.openStore(props.profile)
                    }}>Otwórz sklep</Button>
                </div>
            </div>

        )
    }
    return (
        <>
            <Modal dialogClassName="modal-full1 modal-dialog-centered" show={show} onHide={onHide} onShow={onShow}>
                <Modal.Body>
                    <div className={""} style={{minHeight: 4}}>
                        {renderView()}
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
};

export default RequiredLocationModalComponnent;
